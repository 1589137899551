<template>
  <div class="Header">
    <div class="box">
      <div class="navitems">
        <div :class="['nav', item.path === $route.path && 'select']" v-for="(item, index) in navItems" :key="index"
          @click="goPath(item.path)">
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [{
        path: '/',
        title: '首页'
      }, {
        path: '/about',
        title: '关于我们'
      }, {
        path: '/contact',
        title: '联系我们'
      }, {
        path: '/report',
        title: '付费资讯'
      }]
    }
  },
  methods: {
    goPath(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.Header {
  height: 74px;
  background: #ffffff;

  .navitems {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 74px;

    .nav {
      margin-left: 43px;
      cursor: pointer;
      position: relative;

      :hover {
        color: #1191F7;
      }

      &.select {
        color: #1191F7;

        ::after {
          position: absolute;
          content: '';
          width: 32px;
          height: 2px;
          left: 50%;
          bottom: -10px;
          margin-left: -16px;
          background-color: #1191F7;
        }
      }

      .title {}
    }
  }

}

/* 当视口宽度大于1200px时应用这些样式 */
@media screen and (min-width: 800px) {
  .box {
    min-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

/* 当视口宽度大于600px且小于1200px时应用这些样式 */
@media screen and (min-width: 600) and (max-width: 1200px) {}

/* 当视口宽度小于600px时应用这些样式 */
@media screen and (max-width: 600px) {}
</style>