import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
  // },
  {
    path: "/", // 公众号解决方案
    name: "Public",
    component: () => import(/* webpackChunkName: "public" */ "@/views/public/public.vue"),
  },
  // {
  //   path: "/applet", // 小程序解决方案
  //   name: "Applet",
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/applet/applet.vue"),
  // },
  {
    path: "/about", // 关于我们
    name: "About",
    component: () => import(/* webpackChunkName: "home" */ "@/views/about/about.vue"),
  },
  {
    path: "/contact", // 联系我们
    name: "Contact",
    component: () => import(/* webpackChunkName: "home" */ "@/views/contact/contact.vue"),
  },
  {
    path: "/report", // 精选报告
    name: "Report",
    component: () => import(/* webpackChunkName: "home" */ "@/views/report/report.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
